import * as yup from 'yup'
import { AbLabResultDto } from '../../../types/patients'

export const schema = (selectedResult: AbLabResultDto | undefined) => yup
  .object({
    patient: (selectedResult?.abLabResult?.patientId === null ||
    selectedResult?.abLabResult?.patientId === undefined) ? yup
      .object().shape({
        id: yup.string().required('Required'),
      }).required()
      : yup.object().nullable(),
  })