import React, { useEffect, useRef, useState } from 'react'
import { ConfirmationDialog, DialogV2, Form, Province, Typography, useForm } from 'saga-library/src'
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from '@apollo/client'
import {
  LIST_AB_LAB_RESULT_EXCEPTIONS,
  LINK_AB_LAB_RESULT,
  GET_PATIENT_LAB_AND_INVESTIGATIONS, GET_GENDERS
} from '../../../graphql-definitions'
import _get from "lodash/get";
import { LoadingSpinner } from "../../../components/LoadingScreen";
import { AutocompleteValue, Box, useTheme } from '@mui/material'
import {
  SectionColumnNoElevation,
  SectionContainer, SectionSubHeader
} from "saga-library/src/components/Section/Section";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FormProvider } from "saga-library/src/components/Form";
import { PractitionerSelect } from "../../../components/PractitionersSelect";
import {
  AbLabResult,
  AbLabResultDto,
  AbLabResultDtoInput,
  AbLabResultPractitionerIdentifier
} from '../../../types/patients'
import { PatientLookup } from "../../../components/PatientLookup/PatientLookup";
import { PatientSearchResult } from '../../../types/patients'
import { Permission, PermissionType } from '../../../types/settings/Permission'
import PermissionButton from '../../../components/PermissionButton'
import { schema } from '../util/validation'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { patientDisplayName, practitionerDisplayName } from 'saga-library/src/util/formatting'
import { LIST_AB_LAB_RESULT_EXCEPTION_PRACTITIONERS } from '../../../graphql-definitions/tenant/DashboardQueries'
import { useTenantContext } from '../../../providers/TenantContextProvider'
import { useAccountContext } from '../../../providers/AccountContext'
import { getNewPatientDefaults } from '../../patients/components/labAndInvestigations/LabAndInvestigationsUtil'

interface LabsAndInvestigationsDashboardExceptionModalProps {
  open: boolean
  setOpen: (v: boolean) => void
  onClose?: () => void
}

const EXCEPTION_TEXT_NO_PATIENT = "No linked patient"
const EXCEPTION_TEXT_NO_PRACTITIONER = "No linked practitioner"
const EXCEPTION_TEXT_NO_PATIENT_NO_PRACTITIONER = "No linked patient or practitioner"
const dataTestId = "LabsAndInvestigationsDashboardExceptionModal"

const LabsAndInvestigationsDashboardExceptionModalColumn = ({ children }) => {
  return (
    <SectionColumnNoElevation
      sx={{
        width: '50%',
        overflowY: 'auto',
      }}
    >
      {children}
    </SectionColumnNoElevation>
  )
}

const LabAndInvestigationsDashboardExceptionRowTitle = (result: AbLabResultDto, isSelected: boolean) => {
  return ( <Typography
    variant={'body1'}
    color={(theme) => isSelected ? theme.palette.primary.main : theme.palette.text.primary}
    dataTestId={`${dataTestId}-patientName`}
  >
    {patientDisplayName(result.abLabResult?.patientName?.firstName, result.abLabResult?.patientName?.lastName)}
  </Typography>)
}

const LabAndInvestigationsDashboardExceptionRowSubTitle = (label, isSelected: boolean) => {
  return (<Typography
    variant={'body2'}
    color={(theme) => isSelected ? theme.palette.primary.main : theme.palette.text.secondary}
    dataTestId={`${dataTestId}-subtitle`}
  >
    {label}
  </Typography>)
}

const getPatientInfo = (abLabResult) => {
  const info = [abLabResult?.dob, abLabResult?.gender?.display, abLabResult?.province?.display]
  return info.filter(i => i).join(" · ")
}

export const PatientDetails = ({
  result,
  highlightText
}: {result, highlightText: boolean}) => {
  const patientInfo = getPatientInfo(result?.abLabResult)
  const patientIdentifier = result?.abLabResult.patientIdentifiers?.[0]?.label || "No identifier";

  return (
    <>
      {LabAndInvestigationsDashboardExceptionRowTitle(result, highlightText)}
      {LabAndInvestigationsDashboardExceptionRowSubTitle(patientInfo, highlightText)}
      {LabAndInvestigationsDashboardExceptionRowSubTitle(patientIdentifier, highlightText)}
    </>
  );
}

export const LabsAndInvestigationsDashboardExceptionModal = ({
  open,
  setOpen,
  onClose,
}: LabsAndInvestigationsDashboardExceptionModalProps) => {
  const exceptionRefs = useRef<HTMLDivElement[]>([])
  const FORM_NAME = 'labsAndInvestigationsDashboardExceptionForm'
  const NO_PRACTITIONER_WARNING = "This lab result won't be linked to a practitioner."
  const { tenantIdentifiers } = useTenantContext()
  const { getTenantProvince } = useAccountContext()
  const theme = useTheme();
  const { tenant_id } = useParams()
  const province: Province = getTenantProvince(tenant_id)
  const { showSuccessAlert, showErrorAlert } = useAlerts()
  const [practitionerWarning, setPractitionerWarning] = useState<string|undefined>(undefined)
  const [results, setResults] = useState<AbLabResultDto[]>([])
  const [selectedResult, setSelectedResult] = useState<AbLabResultDto>()
  const [practitionerIdentifiers, setPractitionerIdentifiers] = useState<AbLabResultPractitionerIdentifier[]|null>(null)

  const { data: genderData } = useQuery(GET_GENDERS, {
    fetchPolicy: 'cache-first',
    variables: { provinceCode: province?.code ?? "AB" },
  })

  const errorTextColor = theme.palette.error.main

  useEffect(() => {
    requestAnimationFrame(() => {
      if (exceptionRefs?.current && selectedResult !== undefined) {
        const selectedResultIndex = results.findIndex(r => r.abLabResult.id === selectedResult.abLabResult.id)
        if (selectedResultIndex !== -1) {
          exceptionRefs.current[selectedResultIndex].scrollIntoView({ block: "nearest" })
        }
      }
    })
  }, [results, selectedResult])

  const handleClose = () => {
    setOpen(false)
    onClose?.()
  }

  const PractitionerConfirmationDialog = () => {
    return (
      <ConfirmationDialog
        open={practitionerWarning !== undefined}
        title={'No practitioners linked'}
        message={practitionerWarning}
        primaryLabel={'OK'}
        primaryAction={async () => {
          await linkAsync()
        }}
        onClose={() => {
          setPractitionerWarning(undefined)
        }}
      />
    )
  }

  const { loading: isLoadingExceptions } = useQuery(LIST_AB_LAB_RESULT_EXCEPTIONS, {
    variables: {
      tenantId: tenant_id,
    },
    skip: !open,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      populateList(_get(data, 'tenant.dashboard.listAbLabResultExceptions', []))
    },
  })

  const populateList = (listData: AbLabResultDto[] = [], nextSelectedResultId: string = "") => {
    setResults(listData)
    if (listData.length > 0) {
      if (!!nextSelectedResultId) {
        const nextSelectedResult = listData.find(ld => ld.abLabResult.id === nextSelectedResultId)
        setSelectedResult(nextSelectedResult || listData[0])
      } else {
        setSelectedResult(listData[0]);
      }
      resetForm()
    }
    else{
      setSelectedResult(undefined)
      handleClose()
    }
  }

  const { loading: isLoadingPractitioners } = useQuery(LIST_AB_LAB_RESULT_EXCEPTION_PRACTITIONERS, {
    skip: !selectedResult || !selectedResult.practitionerException,
    variables: {
      tenantId: tenant_id,
      abLabResultId: selectedResult?.abLabResult?.id
    },
    onCompleted: (data) => {
      const practitionerIdentifiers = _get(data, 'tenant.dashboard.listAbLabResultExceptionPractitioners', [])
      setPractitionerIdentifiers(practitionerIdentifiers)
    }
  })

  const [ linkAbLabResult ] = useMutation(LINK_AB_LAB_RESULT,{
    onCompleted: async (data: AbLabResultDto) => {
      showSuccessAlert('lab result has been linked')
      const linkedAbLabResult: any = _get(data, 'tenant.dashboard.linkAbLabResult')
      populateList(linkedAbLabResult?.abLabResultDto, linkedAbLabResult?.nextAbLabResultId)
    },
    onError: async (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert('Lab result could not be linked.')
    },
  })

  const [defaultTValue, setDefaultTValue] = useState<AutocompleteValue<PatientSearchResult, false, false, false> | undefined>();
  useEffect(() => {
    const patientIdentifier = selectedResult?.abLabResult.patientIdentifiers?.[0]?.label;
      setDefaultTValue({
        id: '',
        active: true,
        firstName: '',
        middleName: '',
        lastName: '',
        dob: '',
        email: '',
        primaryPhoneNumber: '',
        primaryIdentifier: '',
        primaryIdentifierLabel: patientIdentifier || '',
        resultType: '',
        patientNotes: [],
        identifiers: [],
        province: ''
      } as AutocompleteValue<PatientSearchResult, false, false ,false>);
  }, [selectedResult]);

  const formMethods = useForm({
    schema: schema(selectedResult),
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting},
  } = formMethods


  const resetForm = () => {
    reset()
    const practitionerFields = formMethods.getValues();
    for (const key in practitionerFields) {
      if (key.startsWith('practitionerId-')) {
        formMethods.setValue(key, null);
      }
    }
  }

  const rowClicked = async (abLabResult: AbLabResultDto) => {
    setPractitionerIdentifiers(null)
    setSelectedResult(abLabResult)
    resetForm()
  }

  const getAbLabResultExceptionText = (result: AbLabResultDto) => {
    const hasPractitionerException = result.practitionerException;
    const hasPatient = Boolean(result.abLabResult.patientId);

    let label;
    if (hasPractitionerException && hasPatient) {
      label = EXCEPTION_TEXT_NO_PRACTITIONER;
    } else if (hasPractitionerException && !hasPatient) {
      label = EXCEPTION_TEXT_NO_PATIENT_NO_PRACTITIONER;
    } else {
      label = EXCEPTION_TEXT_NO_PATIENT;
    }

    return label;
  }

  const labAndInvestigationsDashboardExceptionRow = (result: AbLabResultDto, index: number) => {
    let isSelected = false
    if(selectedResult?.abLabResult?.id === result?.abLabResult?.id){
      isSelected = true
    }

    const currentExceptionText = () => {
      let label = getAbLabResultExceptionText(result)
      return (
        <Box
          gap={ '4px' }
          display = { 'flex' }
          alignItems={'center'}
        >
          <ErrorOutlineIcon
            sx={{ color: errorTextColor }}
            data-testid={`${dataTestId}-icon`}
          />
          <Typography
            color={errorTextColor}
            sx={{ flex: '1 1 auto', fontWeight: 700 }}
            variant={'body2'}
            dataTestId={`${dataTestId}-message`}
          >
            {label}
          </Typography>
        </Box>
      )
    }

    return (
      <Box
        key = {`labRow-${result?.abLabResult?.id}-${index}`}
        ref={(el) => exceptionRefs.current[index] = el as HTMLDivElement}
        onClick={() => rowClicked(result)}
        display = { 'flex' }
        sx = {{
          gap: '8px',
          flex: '1 1 auto',
          maxHeight: '65px',
          alignItems: 'center',
          borderTop: '1px solid',
          borderColor: theme.palette.greys.light,
          p: 1,
          backgroundColor: isSelected ? 'backgrounds.selected' : 'backgrounds.none',
          '&:hover': {
            backgroundColor: isSelected ? 'backgrounds.selectedHover' : 'backgrounds.hover',
            cursor: 'pointer',
          },
        }}
      >
        <Box
          sx = {{
            width: '50%',
          }}
        >
          <PatientDetails result={result} highlightText={isSelected}/>
        </Box>
        {currentExceptionText()}
      </Box>
    )
  }

  const PatientRowDetails = (result: AbLabResultDto|undefined, key) => {
    let showPatientSelect = false
    if (result) {
      let exceptionText = getAbLabResultExceptionText(result)
      showPatientSelect = (exceptionText === EXCEPTION_TEXT_NO_PATIENT ||
        exceptionText === EXCEPTION_TEXT_NO_PATIENT_NO_PRACTITIONER)
    }

    if(!showPatientSelect) return <></>
    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'8px'}
      >
        <SectionSubHeader
          variant={'h3'}
          dataTestId={`${dataTestId}-sectionSubHeader-patient`}
          sx={{ m: 0 }}
        >
          Patient
        </SectionSubHeader>
        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{
            gap: '16px',
            flex: '1 1 auto',
          }}
        >
          <Box
            sx={{
              pt: 1,
              maxWidth: '180px',
              width: '40%',
            }}
          >
            <PatientDetails result={result} highlightText={false}/>
          </Box>
          <PatientLookup
            key={'patient-select' + key}
            defaultTValue={defaultTValue ? defaultTValue : undefined}
            getNewDefaults={result ? () => getNewPatientDefaults(result?.abLabResult, tenantIdentifiers, genderData?.static?.gendersByProvince) : undefined}
            name={"patient"}
            label={"Patient"}
            sx={{ width: '100%' }}
            patientLabelSx={{ flexGrow: 1, width: '360px' }}
            patientLabelVariant={'md'}
          />
        </Box>
      </Box>
    )
  }

  const PractitionerRowDetails = (result: AbLabResultDto, practitioner: AbLabResultPractitionerIdentifier|null, practitionerId: string|null, index) => {
    let showPractitionerSelect = false
    if (result){
      let exceptionText = getAbLabResultExceptionText(result)
      showPractitionerSelect = (exceptionText === EXCEPTION_TEXT_NO_PRACTITIONER ||
        exceptionText === EXCEPTION_TEXT_NO_PATIENT_NO_PRACTITIONER)
    }

    return (
      showPractitionerSelect && (
        <Box
          key={`practitionerRow-${result?.abLabResult?.id}-${index}`}
          display = { 'flex' }
          sx = {{
            gap: '16px',
            flex: '1 1 auto',
            maxHeight: '65px',
            alignItems: 'center',
            alignSelf: 'stretch'
          }}
        >
          <Box
            key={`practitionerRowName-${result?.abLabResult?.id}-${index}`}
            sx = {{
              maxWidth: '180px',
              width: '40%',
              display: practitioner ? 'auto' : 'none'
            }}
          >
            {
              practitioner &&
              practitionerDisplayName(practitioner.firstName ?? "", practitioner.lastName ?? "")
            }
          </Box>
          <PractitionerSelect
            key={`${practitionerId ? '' : 'practitionerId'}-${result?.abLabResult?.id}-${index}`}
            name={`practitionerId-${index}`}
            label={"Practitioner"}
            sx={{ width: '100%' }}
            dataTestId={`${dataTestId}-${index}-practitionerSelect`}
          />
        </Box>
      )
    )
  }

  const getPractitionerIdentifierSelections = (data) => {
    if(!practitionerIdentifiers) {
      return []
    }
    else if(practitionerIdentifiers?.length === 0) {
      let selectedPractitionerId = data[`practitionerId-0`]
      return [
        {
          practitionerId: selectedPractitionerId,
          identifier: '',
          id: '',
          version: "0",
        }
      ]
    }else {
      return practitionerIdentifiers.map((practitioner, index) => {
        let selectedPractitionerId = data[`practitionerId-${index}`]
        return {
          // This is the id of the selected practitioner from the dropdown
          practitionerId: selectedPractitionerId,
          // This is the identifier of the practitioner from the lab result
          identifier: practitioner.identifier,
          // This is the id of the practitioner from the lab result
          id: practitioner.id,
          version: "0",
        }
      })
    }
  }

  const getAbLabResultInput = () => {
    if(selectedResult){
      const data = formMethods.getValues()
      const abLabResultInput: AbLabResultDtoInput = {
        version: selectedResult.abLabResult.version,
        abLabResultId: selectedResult.abLabResult.id,
        patientId: data.patient ? data.patient.id : selectedResult.abLabResult.patientId,
        practitionerIdentifiers: getPractitionerIdentifierSelections(data)
      }
      return abLabResultInput
    }
  }

  const linkAsync = async () => {
    setPractitionerWarning(undefined)
    const input = getAbLabResultInput()

    await linkAbLabResult({
      variables: {
        tenantId: tenant_id,
        abLabResultInput: input
      },
      update: (cache) => {
        if (!input) return
        cache.updateQuery({
            query: GET_PATIENT_LAB_AND_INVESTIGATIONS,
            variables: { tenantId: tenant_id, patientId: input.patientId },
            returnPartialData: true
          },
          (data) => {
            const currentList = _get(data, 'tenant.patient.labAndInvestigation.list', null) as AbLabResult[] | null
            if (!currentList) return data
            return {
              tenant: {
                ...data.tenant,
                patient: {
                  ...data.tenant.patient,
                  labAndInvestigation: {
                    ...data.tenant.patient.labAndInvestigation,
                    list: [
                      ...currentList.filter(l => l.id !== input.abLabResultId),
                      { ...selectedResult?.abLabResult, patientId: input.patientId}
                    ]
                  }
                }
              }
            }
          }
        )
      }
    })
  }

  const onSubmit = handleSubmit(
    async (data) => {
      let practitionerErrorCount = 0
      const entries = Object.entries(data).filter(([key]) => key.includes('practitionerId'));
      entries.map(([key, value]) => {
        if(!value) {
          practitionerErrorCount += 1
        }
      })

      if(entries.length !== 0 && entries.length === practitionerErrorCount) {
        setPractitionerWarning(NO_PRACTITIONER_WARNING)
      }
      else {
        await linkAsync()
      }
    },
    async (errors) => {
      const errorMessage = errors.patient
        ? "Patient is required."
        : "There was an error linking patient and/or practitioner.";

      showErrorAlert(errorMessage)
    }
  )

  const PatientIdentifierSection = () => {
    return PatientRowDetails(selectedResult, selectedResult?.abLabResult?.patientId)
  }

  const PractitionerIdentifiersSection = () => {
    if(isLoadingPractitioners) return <LoadingSpinner />
    if(selectedResult?.practitionerException && !!practitionerIdentifiers) {
      return (
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'8px'}
          key={`practitionerSection-${selectedResult?.abLabResult?.id}`}
        >
          <SectionSubHeader
            variant={'h3'}
            dataTestId={`${dataTestId}-sectionSubHeader-practitioner`}
            sx={{ m: 0 }}
          >
            Practitioner
          </SectionSubHeader>
          {
            practitionerIdentifiers?.length === 0
              ? PractitionerRowDetails(selectedResult, null, null, 0)
              : (
                practitionerIdentifiers?.map((practitioner, index) =>
                  PractitionerRowDetails(selectedResult, practitioner || null, practitioner.id || null, index))
              )
          }
        </Box>
      )
    }
    else {
      return <></>
    }
  }

  return (
    <>
      { practitionerWarning && <PractitionerConfirmationDialog /> }
      <DialogV2
        open={open}
        title={"Lab and investigation result exceptions"}
        primaryAction={()=> null}
        overridePrimaryComponent={
          <PermissionButton
            name={"linkExceptions"}
            type={"submit"}
            form={FORM_NAME}
            requiredType={PermissionType.Chart}
            requiredPermission={Permission.READWRITE}
            loading={isSubmitting}
          >
            Link
          </PermissionButton>
        }
        showCancelButton={false}
        onClose={handleClose}
        size={'lg'}
        data-testid={`${dataTestId}-dialog`}
        preventScroll={true}
        PaperProps={{sx:{height: '100%', maxHeight: '78vh'}}}
      >
        {isLoadingExceptions && <LoadingSpinner />}
        {results.length > 0 && (
          <SectionContainer sx={{gap: '32px'}}>
            <LabsAndInvestigationsDashboardExceptionModalColumn>
              {
                results.map((result, index) => (
                  labAndInvestigationsDashboardExceptionRow(result, index)
                ))
              }
            </LabsAndInvestigationsDashboardExceptionModalColumn>
            <LabsAndInvestigationsDashboardExceptionModalColumn>
              <FormProvider {...formMethods}>
                <Form
                  id={FORM_NAME}
                  name={FORM_NAME}
                  onSubmit={onSubmit}
                >
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={'16px'}
                  >
                    <PatientIdentifierSection />
                    <PractitionerIdentifiersSection />
                  </Box>
                </Form>
              </FormProvider>
            </LabsAndInvestigationsDashboardExceptionModalColumn>
          </SectionContainer>
        )}
      </DialogV2>
    </>
  )
}
